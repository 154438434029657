import React from "react";
import styled from "@emotion/styled";
import { Spin } from "antd";

const Loader = styled.div`
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
`;

const Loading: React.FC = () => {
  return (
    <Loader data-testid="full-page-loader">
      <Spin size="large" />
    </Loader>
  );
};

export default Loading;
