export const tagOptions = [
  { label: "Product", value: "Product" },
  { label: "Story", value: "Story" },
  { label: "Brand", value: "Brand" },
];

export enum ROUTES {
  home = "/",
  dashboard = "/dashboard",
  page403 = "/403",
  page404 = "/404",
  page500 = "/500",
}

export enum LOCAL_STORAGE_TEMPLATE {
  token = "Bearer",
}
