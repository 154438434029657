import React, { Suspense } from "react";
import { Switch, Route } from "react-router-dom";

import { Global } from "@emotion/react";
import { ROUTES } from "../../common/constants";
import PrivateRoute from "./PrivateRoute";
import Loading from "../../components/Loading";
import "../../App.less";
import { GlobalStyle } from "../../styles/global";

const Home = React.lazy(() => import("../home"));
const Dashboard = React.lazy(() => import("../dashboard"));

const App: React.FC = () => {
  return (
    <Suspense fallback={<Loading />}>
      <Switch>
        <Route exact path={ROUTES.home}>
          <Home />
        </Route>
        <PrivateRoute path={ROUTES.dashboard}>
          <Dashboard />
        </PrivateRoute>
      </Switch>
      <Global styles={GlobalStyle} />
    </Suspense>
  );
};

export default App;
